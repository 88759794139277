import React from "react"
import { DiscussionEmbed } from "disqus-react"

import "./Comments.scss"

const Comments = ({ identifier, title }) => {
  const disqusConfig = {
    shortname: "inapp-blog",
    config: { identifier, title },
  }

  return (
    <div className="PostComments">
      <div className="PostComments__Wrapper">
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </div>
  )
}

export default Comments
