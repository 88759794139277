import React from "react"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import "./PostBody.scss"

const PostBody = ({ html, previous, next }) => {
  const handlePrevNextClick = direction => {
    trackCustomEvent({
      category: "More posts click",
      action: `Click On The ${direction} Post`,
    })
  }

  return (
    <article className="PostBody">
      <section
        className="PostBody__Wrapper"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="PostBody__PrevNext">
        {previous && (
          <Link
            className="PostBody__PrevNext__Previous"
            to={previous.fields.slug}
            onClick={() => handlePrevNextClick("Previous")}
          >
            ← <span style={{ fontWeight: 400 }}>Previous:</span>{" "}
            {previous.frontmatter.title}
          </Link>
        )}
        {next && (
          <Link
            className="PostBody__PrevNext__Next"
            to={next.fields.slug}
            onClick={() => handlePrevNextClick("Next")}
          >
            <span style={{ fontWeight: 400 }}>Next:</span>{" "}
            {next.frontmatter.title} →
          </Link>
        )}
      </div>
    </article>
  )
}

export default PostBody
