import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { OutlineButton } from "../../atoms"

import inappLogo from "../../../../content/assets/logo.png"

import "./PostLanding.scss"

const PostLanding = ({ title, mainImage, author, date }) => {
  const isSmaller = useMediaQuery("(max-width:920px)")

  const handleLogoClick = () => {
    trackCustomEvent({
      category: "Logo click",
      action: "Click On Post Page Logo",
    })
  }

  return (
    <div className="PostLanding" id="post-landing">
      <BackgroundImage
        className="PostLanding__MainImage"
        fluid={mainImage.childImageSharp.fluid}
      >
        <div className="PostLanding__MainImage__Wrapper">
          <div className="PostLanding__MainImage__Wrapper__Header">
            <a href="https://www.inappsystems.com/" onClick={handleLogoClick}>
              <img
                className="PostLanding__MainImage__Wrapper__Header__Logo"
                src={inappLogo}
                alt="InApp Systems logo"
              />
            </a>
          </div>
          <div className="PostLanding__MainImage__Wrapper__Details">
            <h1 className="PostLanding__MainImage__Wrapper__Details__Title">
              {title}
            </h1>
          </div>
          {!isSmaller ? (
            <div className="PostLanding__MainImage__Wrapper__More">
              <div className="PostLanding__MainImage__Wrapper__More__Empty"></div>
              <div className="PostLanding__MainImage__Wrapper__More__Details">
                <p>
                  By {author}&emsp;|&emsp;{date}
                </p>
              </div>
              <Link to="/">
                <OutlineButton
                  label="← Back To Blog"
                  fontSize="1.4rem"
                  width="200px"
                  height="52px"
                  fontWeight="400"
                />
              </Link>
            </div>
          ) : (
            <div className="PostLanding__MainImage__Wrapper__More-Mobile">
              <p>By {author}</p>
              <p>{date}</p>
              <Link to="/">
                <OutlineButton
                  label="Back To Blog"
                  fontSize="1.3rem"
                  width="180px"
                  height="48px"
                  fontWeight="400"
                />
              </Link>
            </div>
          )}
        </div>
      </BackgroundImage>
    </div>
  )
}

export default PostLanding
