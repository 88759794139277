import React from "react"
import { Link } from "gatsby"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { LandingContentItem } from "../../LandingPage/LandingContent/LandingContentItem"
import { Button } from "../../atoms"

import "./MorePosts.scss"

const MorePosts = ({ posts }) => {
  const isSmaller = useMediaQuery("(max-width:1160px)")
  const isMobile = useMediaQuery("(max-width:680px)")

  const getFinalPosts = () => {
    if (isSmaller && !isMobile) {
      return posts
    }

    return posts.slice(0, 3)
  }

  return (
    <div className="MorePosts">
      <div className="MorePosts__Wrapper">
        <div className="MorePosts__Wrapper__Head">
          <p className="MorePosts__Wrapper__Head__MoreTitle">Latest Posts</p>
          <Link to="/">
            <Button
              label="View All Posts"
              fontSize={!isMobile ? "1.4rem" : "1.3rem"}
              width={!isMobile ? "184px" : "164px"}
              height={!isMobile ? "45px" : "43px"}
              fontWeight="400"
            />
          </Link>
        </div>
        <div className="MorePosts__Wrapper__ContentItems">
          {getFinalPosts().map(({ node }) => (
            <LandingContentItem
              key={node.fields.slug}
              id={node.frontmatter.title}
              isMorePostsClick={true}
              to={node.fields.slug}
              mainImage={node.frontmatter.mainImage}
              title={node.frontmatter.title}
              shortText={node.excerpt}
              author={node.frontmatter.author}
              time={node.frontmatter.date}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MorePosts
