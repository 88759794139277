import React from "react"
import { graphql } from "gatsby"

import { Layout } from "../components/atoms"
import { PostLanding, PostBody, MorePosts, Comments } from "../components/Post"
import { Subscribe } from "../components/Subscribe"
import { Footer } from "../components/Footer"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.post
  const { previous, next } = pageContext

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.mainImage}
        relativeUrl={post.fields.slug}
      />
      <PostLanding
        title={post.frontmatter.title}
        mainImage={post.frontmatter.mainImage}
        author={post.frontmatter.author}
        date={post.frontmatter.date}
      />
      <PostBody html={post.html} previous={previous} next={next} />
      <Comments identifier={post.fields.slug} title={post.frontmatter.title} />
      <MorePosts posts={data.morePosts.edges} />
      <Subscribe />
      <Footer scrollToId="post-landing" />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        author
        mainImage {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    morePosts: allMarkdownRemark(
      filter: { fields: { slug: { ne: $slug } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          excerpt(pruneLength: 126)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            mainImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
